.clear-filter[filter-color="blue"] {
  @include linear-gradient(rgba($black-color,.20), rgba(3, 161, 224, 0.6));
}
[data-background-color="blue"]{
    background-color: $info-color;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header{
  &.page-header-small{
    min-height: 60vh;
    max-height: 560px;
  }
}
.section-story-overview{
  padding: 50px 10% 5% 15%
}

.section {
  padding-top: 25px;
}

.solution {
  padding-bottom: 0;
}