img{
    max-width: 100%;
    border-radius: 1px;
}
.img-raised{
    box-shadow: $box-shadow-raised;
}
.box{
    max-width: 100%;
    border-radius: 5%;
    height: 200px;
}
.smallbox{
    max-width: 48px;
    height: 48px;
}
.logobox {
  max-width: 100%;
  height: 55px;
}
